import { NavigationDropdown, NavigationItem, NavigationLink } from '../../../@vex/interfaces/navigation-item.interface';
export interface NavItem {
  type?: string;
  label?: string;
  route?: string;
  groupNames?: string[];
  icon?: string
  routerLinkActiveOptions?: any;
}

export const ALL_NAV_ROUTES: Array<NavigationLink | NavigationDropdown> = [
  // ARTWORK Links
  { type: 'link', label: 'Sales', route: 'admin/artwork-sales', groupNames: ['ARTWORK'], icon: "insights", value: 'SALES' },
  { type: 'link', label: 'Designs', route: 'admin/designs', groupNames: ["ARTWORK"], icon: "brush" },
  //ADMIN Links
  { type: 'link', label: 'Home', route: 'admin/home', groupNames: ["ADMIN", "READ_ONLY"], icon: "home" },
  { type: 'link', label: 'Stores', route: 'admin/schools', groupNames: ["ADMIN", "READ_ONLY", "ARTWORK"], icon: "school" },
  { type: 'link', label: 'Commissions', route: 'admin/commissions', groupNames: ["ADMIN", "READ_ONLY"], icon: "school", tags: ['hasCommissions'] },
  { type: 'link', label: 'Athlete Sign Up', route: 'admin/athletes', groupNames: ["ADMIN", "READ_ONLY"], icon: "sports" },
  {  type: 'dropdown', label: "Referrals", icon: 'group_add', groupNames: ["ADMIN", "READ_ONLY"],
    children: [
      { type: 'link', label: 'Brand Ambassadors', route: 'admin/referrals/brand-ambassadors', groupNames: ["ADMIN", "READ_ONLY"], icon: "group_add" },
      { type: 'link', label: 'Rewards', route: 'admin/referrals/rewards', groupNames: ["ADMIN", "READ_ONLY"], icon: "group_add" },
    ]
  },
  { type: 'link', label: 'Product Bundles', route: 'admin/bundles', groupNames: ["ADMIN", "READ_ONLY"], icon: "inventory_2" },
  { type: 'link', label: 'Users', route: 'admin/users', groupNames: ["ADMIN", "READ_ONLY"], icon: "person" },
  { type: 'link', label: 'Organizations', route: 'admin/organizations', groupNames: ["ADMIN", "READ_ONLY"], icon: "corporate_fare" },
  { type: 'link', label: 'Quotes', route: 'admin/quotes', groupNames: ['ADMIN', 'READONLY'], icon: "request_quote" },
  { type: 'link', label: 'Production', route: 'admin/production', groupNames: ['ADMIN', 'READONLY'], icon: "precision_manufacturing" },
  { type: 'link', label: 'Build', route: 'admin/build', groupNames: ["ADMIN", "READ_ONLY"], icon: "build" },
  { type: 'link', label: 'Reports', route: 'admin/reporting', groupNames: ["ADMIN", "READ_ONLY"], icon: "payments" },
  // { type: 'link', label: 'Payouts', route: 'admin/payouts', groupNames: ["ADMIN", "READ_ONLY"], icon: "paid" },
  { type: 'link', label: 'License', route: 'admin/license', groupNames: ["ADMIN", "READ_ONLY"], icon: "badge" },
  { type: 'link', label: 'Artwork', route: 'admin/artwork', groupNames: ["ADMIN", "READ_ONLY"], icon: "brush" },
  {
    type: 'dropdown', label: "Admin Config", icon: 'list_alt', groupNames: ["ADMIN", "READ_ONLY"],
    children: [
      { type: 'link', label: 'Models', route: 'admin/models', groupNames: ["ADMIN", "READ_ONLY"], icon: "inventory_2" },
      { type: 'link', label: 'Price Lists', route: 'admin/price-lists', groupNames: ["ADMIN", "READ_ONLY"], icon: "inventory_2" },
      { type: 'link', label: 'Codes', route: 'admin/codes', groupNames: ["ADMIN", "READ_ONLY"], icon: "subtitles" },
      { type: 'link', label: 'Documents Templates', route: 'admin/documents', groupNames: ["ADMIN", "READ_ONLY"], icon: "history_edu" },
      { type: 'link', label: "Templates", groupNames: ["ADMIN"], route: "admin/templates", icon: "list_alt" },
    ],
  },
  {
    type: 'dropdown', label: "Work Stations", icon: 'list_alt', groupNames: ["ADMIN", "READ_ONLY"],
    children: [
      { type: 'link', label: 'Job Batching', route: 'workstations/job-batching', groupNames: ["ADMIN", "READ_ONLY"], icon: "ship" },
      { type: 'link', label: 'Batch Status', route: 'workstations/batches', groupNames: ["ADMIN", "READ_ONLY"], icon: "toc" },
      { type: 'link', label: 'Heat Press', route: 'workstations/heatpress', groupNames: ["ADMIN", "READ_ONLY"], icon: "compress" },
      { type: 'link', label: 'Embroidery', route: 'workstations/embroidery', groupNames: ["ADMIN", "READ_ONLY"], icon: "compress" },
      { type: 'link', label: 'Embroidery Admin', route: 'workstations/embroidery-admin', groupNames: ["ADMIN", "READ_ONLY"], icon: "compress" },
      { type: 'link', label: 'QC Marshalling', route: 'workstations/qc-marshalling', groupNames: ["ADMIN", "READ_ONLY"], icon: "inventory_2" },
      { type: 'link', label: 'Shipping', route: 'workstations/shipping', groupNames: ["ADMIN", "READ_ONLY"], icon: "ship" },
      { type: 'link', label: 'Shipping Batches', route: 'workstations/shipping-batches', groupNames: ["ADMIN", "READ_ONLY"], icon: "ship" },
      { type: 'link', label: 'Orders', route: 'workstations/orders', groupNames: ["ADMIN", "READ_ONLY"], icon: "ship" },
      { type: 'link', label: 'Picking', route: 'workstations/picking', groupNames: ["ADMIN", "READ_ONLY"], icon: "ship" },
      { type: 'dropdown', label: "Inventory", icon: 'list_alt', groupNames: ["ADMIN", "READ_ONLY"],
        children: [
          { type: 'link', label: 'Purchasing', route: 'workstations/purchasing', groupNames: ["ADMIN", "READ_ONLY"], icon: "ship" },
          { type: 'link', label: 'Stocking', route: 'workstations/stocking', groupNames: ["ADMIN", "READ_ONLY"], icon: "ship" },
          { type: 'link', label: 'Live Inventory', route: 'workstations/live-inventory', groupNames: ["ADMIN", "READ_ONLY"], icon: "ship" },
          { type: 'link', label: 'Stock Locations', route: 'workstations/stock-locations', groupNames: ["ADMIN", "READ_ONLY"], icon: "ship" },
        ]
      }

    ],
  },

  //PLAYER Links
  { type: 'link', label: 'Home', route: 'player/home', groupNames: ['PLAYER'], icon: "home", value: 'HOME' },
  { type: 'link', label: 'NIL+', route: 'player/nil-plus', groupNames: ['PLAYER'], icon: "add_circle", value: 'NIL+', tags: ['nilPlus'] },
  { type: 'link', label: 'Store Settings', route: 'player/store', groupNames: ['PLAYER'], icon: "store", value: 'STORE' },
  { type: 'link', label: 'Sales', route: 'player/sales', groupNames: ['PLAYER'], icon: "insights", value: 'SALES' },
  { type: 'link', label: 'Referrals', route: 'player/referrals', groupNames: ['PLAYER'], icon: "group_add", value: 'REFERRALS', tags: ['playerSignUps'] },
  { type: 'link', label: 'Payouts', route: 'user/payouts', groupNames: ['PLAYER'], icon: "paid", value: 'SALES', tags: ['noPayouts'] },
  { type: 'link', label: 'Designs', route: 'player/designs', groupNames: ['PLAYER'], icon: "draw", value: 'DESIGNS' },
  { type: 'link', label: 'Document', route: 'player/documents', groupNames: ['PLAYER'], icon: "description", value: 'DOCUMENTS' },

  //SCHOOL Links
  { type: 'link', label: 'Home', route: 'school/home', groupNames: ["SCHOOL"], icon: "home" },
  {
    type: 'dropdown', label: "Store Settings", icon: 'store', groupNames: ["SCHOOL"],
    children: [
      { type: 'link', label: 'Products', route: 'school/products', groupNames: ["SCHOOL"], icon: "inventory_2" },
      { type: 'link', label: 'Designs', route: 'school/designer', groupNames: ["SCHOOL"], icon: "draw" },
      { type: 'link', label: 'Store Layout', route: 'school/store-settings', groupNames: ["SCHOOL"], icon: "store", tags: ['afterBuild'] },
    ],
  },
  { type: 'link', label: 'Sales', route: 'school/sales', groupNames: ["SCHOOL"], icon: "paid" },
  // { type: 'link', label: 'License Agreements', route: 'school/license-agreements', groupNames: ["SCHOOL"], icon: "handshake" },
  { type: 'link', label: 'Disclosures', route: 'school/disclosures', groupNames: ["SCHOOL"], icon: "gavel" },
  { type: 'link', label: 'Rosters', route: 'school/rosters', groupNames: ["SCHOOL"], icon: "device_hub" },
  { type: 'link', label: 'Brand Settings', route: 'school/settings', groupNames: ["SCHOOL"], icon: "settings" },

  //Org Links
  { type: 'link', label: 'Home', route: 'org/home', groupNames: ["ORG"], icon: "home" },
  // { type: 'link', label: 'Schools', route: 'org/schools', groupNames: ["ORG"], icon: "school" },
  { type: 'link', label: 'Players', route: 'org/players', groupNames: ["ORG"], icon: "group" },
  { type: 'link', label: 'Users', route: 'org/users', groupNames: ["ORG"], icon: "person" },
  // { type: 'link', label: 'Products', route: 'org/products', groupNames: ["ORG"], icon: "inventory_2" },


  //MANUFACTURER links
  { type: 'link', label: 'Sales', route: 'manufacturer/home', groupNames: ["MANUFACTURER"], icon: "home" },
  { type: 'link', label: 'Schools', route: 'manufacturer/schools', groupNames: ["MANUFACTURER"], icon: "school" },
  { type: 'link', label: 'Athlete Agreements', route: 'manufacturer/athletes', groupNames: ["MANUFACTURER"], icon: "description"},
  { type: 'link', label: 'Payouts', route: 'manufacturer/payouts', groupNames: ["MANUFACTURER"], icon: "paid" },
]
